@import "~@tdcerhverv/parrotfish/dist/colors-design-token.scss";

$contentWrapperWidth: 768px;

.topbar {
  padding: 0.75rem 1.5rem;
  background: map-get($theme, "white");
  box-sizing: border-box;
  position: relative;
  z-index: 3;
  box-shadow: 0 0 1rem 0 rgba(map-get($theme, 'black'), 0.45);

  svg {
    width: 7rem;
    height: 2.5rem;
  }

  @media screen and (min-width: $contentWrapperWidth) {
    padding: 1rem 3rem;
  }
}
