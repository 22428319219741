$contentWrapperWidth: 768px;

// grid parent-child relationship description needs to reside in the same file
// so autoprefixer can act upon it and make it IE11 compatible
main {
  $uid: unique-id(); // grid unique id
  $gridAreas: 'TopBar', 'Banner', 'Article', 'Usabilla', 'Footer';

  display: grid;
  grid-template-areas:
    "TopBar_#{$uid} TopBar_#{$uid} TopBar_#{$uid}"
    "Banner_#{$uid} Banner_#{$uid} Banner_#{$uid}"
    ". Article_#{$uid} ."
    ". Usabilla_#{$uid} ."
    "Footer_#{$uid} Footer_#{$uid} Footer_#{$uid}";
  grid-template-rows: auto auto auto 1fr auto;
  grid-template-columns: 1fr $contentWrapperWidth 1fr;

  @media screen and (max-width: $contentWrapperWidth) {
    grid-template-columns: 1fr 100% 1fr;
  }

  @each $area in $gridAreas {
    & > .gridPlace#{$area} {
      grid-area: #{$area}_#{$uid};
    }
  }
}
